import React from 'react'
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Layout from '../layouts/layout-fixed'

const NotFoundPage = () => (
  <Layout>
    <Helmet htmlAttributes={{ class:'notfoundpage fixed' }} />
    <SEO title="Quotes" />
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
      }}
      className="fade-in"
        >
        <h1>Don't worry, be happy !</h1>
        <p>Everything will be okay in the end. If it's not okay, it's not the end...</p>
        <p>The greatest teacher failure is.</p>
    </div>
  </Layout>
)

export default NotFoundPage
